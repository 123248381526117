<template>
  <div id="login">
    <!-- <el-row gutter="20" height="200"></el-row> -->

    <el-row>
      <el-col :span=20 offset="2">
        <el-container>
          <el-main>
            <el-card class="box-card" shadow="always">
              <div slot="header" class="login_title">管理系统</div>
              <el-form
                ref="form"
                :model="form"
                label-width="80px"
                v-loading="loading"
                element-loading-text="登陆中"
              >
                <el-form-item label="用户名">
                  <el-input v-model="form.userName"></el-input>
                </el-form-item>
                <el-form-item label="密码">
                  <el-input v-model="form.pwd" show-password></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="login">登陆</el-button>
                </el-form-item>
              </el-form>
            </el-card>
          </el-main>
        </el-container>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "Login",
  data: function () {
    return {
      form: {
        userName: "",
        pwd: "",
      },

      loading: false,
    };
  },

  methods: {
    login() {
      this.$store.dispatch("auth/login", this.form);
    },
  },
};
</script>

<style scoped>
.login_title {
  width: 100%;
  text-align: center;
}
</style>